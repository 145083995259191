@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'antd/dist/antd.css';
.text-question {
  border-radius: 40px;
  border-top-left-radius: 0;
  text-align: center;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 5%);
}

.card-sendmail {
  border-radius: 40px;
  border-top-left-radius: 0;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 5%);
  padding: 40px;
}
:root {
  scroll-behavior: smooth;
}
.react-datepicker__triangle {
  display: none !important;
}
@layer components {
  .active {
    @apply bg-secondarydark #{!important};
  }
  .sendMail {
    @apply border border-gray-300 outline-none focus:border-blue-500  p-4 rounded-md;
  }
  .btn-sendMail {
    @apply bg-primary hover:bg-primarydark p-4 rounded-md;
  }
  .input-purchase {
    @apply bg-cultured outline-none border-b border-gray-300 mb-1 focus:border-primary;
  }
  .text-label-purchase {
    @apply text-base text-primary;
  }
}
