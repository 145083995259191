#header {
  transition: height 0.5s ease;
}
.bugger {
  width: 3.75rem;
  height: 3.75rem;
  position: relative;
}
.bugger span {
  display: block;
  width: 1.5rem;
  height: 2px;
  background-color: #000;
  transition: background-color 0.5s linear;
  transform: translateY(12px);
}

.bugger span::before {
  content: '';
  position: absolute;
  display: block;
  width: 1.5rem;
  height: 2px;
  background-color: #000;
  left: 0;
  top: -7px;
  z-index: 1;
  transform-origin: top left;
  transition: transform 0.5s linear;
}

.bugger span::after {
  content: '';
  position: absolute;
  display: block;
  width: 1.5rem;
  height: 2px;
  background-color: #000;
  left: 0;
  top: 7px;
  z-index: 1;
  transform-origin: bottom left;
  transition: transform 0.5s linear;
}

.bugger.clicked span::before {
  transform: rotate(45deg);
  top: -8px;
  background-color: #000;
}

.bugger.clicked span::after {
  transform: rotate(-45deg);
  top: 8px;
  background-color: #000;
}

.bugger.clicked span {
  background-color: transparent;
}
.nav-item {
  animation:  ease 0.5s normal forwards 1 fadein;
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.btn-lang:active {
  transform: scale(90%);
  opacity: 0.5;
}
