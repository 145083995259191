.loading-wrapper {
  position: fixed; /* Sit on top of the page content */
  width: 100% !important; /* Full width (cover the whole page) */
  height: 100% !important; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  margin: 0 !important;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.loading-inner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
